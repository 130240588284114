$ ->

	$('#contact_contact_type_manufacturing').change ->
		t = '#manufacturing'
		if $(this).prop("checked") == true
			$(t).show()
		else
			$(t).hide()
		return
	$('#contact_contact_type_manufacturing').trigger 'change'
	return

$ ->
	$('#contact_contact_type_distillation').change ->

		t = '#distillation'
		if $(this).prop("checked") == true
			$(t).show()
		else
			$(t).hide()
		return
	$('#contact_contact_type_distillation').trigger 'change'
	return
$ ->
	$('#contact_contact_type_formulation').change ->
		t = '#formulation'
		if $(this).prop("checked") == true
			$(t).show()
		else
			$(t).hide()
		return
	$('#contact_contact_type_formulation').trigger 'change'
	return
$ ->
	$('#contact_contact_type_resinpolymerization').change ->
		t = '#resinpolymerization'
		if $(this).prop("checked") == true
			$(t).show()
		else
			$(t).hide()
		return
	$('#contact_contact_type_resinpolymerization').trigger 'change'
	return
$ ->
	$('#contact_contact_type_distributed').change ->
	
		t = '#distributed'
		if $(this).prop("checked") == true
			$(t).show()
		else
			$(t).hide()
		return
	$('#contact_contact_type_distributed').trigger 'change'
	return
