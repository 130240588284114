
$(function(){
    $('a[href^="#"]').click(function() {
        let speed = 400;
        let href= $(this).attr("href");
        let target = $(href == "#" || href == "" ? 'html' : href);
        let position = target.offset().top - 50;
        let pc = target.offset().top -55;
        let w = $(window).width();
        let x = 1023;
        if(w <= x){
            $('body,html').animate({scrollTop:position}, speed, 'swing');
            return false;
        }else{
            $('body,html').animate({scrollTop:pc}, speed, 'swing');
        }
     });
})
